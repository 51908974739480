import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { getData, addData, editData, deleteData, patchData } from '../api/data';
import { cloneDeep } from 'lodash';
import { hideLoader, setError, setLoader } from './quantomState';

export const projectsURI = 'projects';
export const notificationsURI = 'notifications';
export const tasksURI = 'tasks';
export const tagsURI = 'tags';

export const fetchProjects = createAsyncThunk(
  `${projectsURI}/fetch`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      const state = getState();

      const { page, perPage, search, sortDirection, sortColumn } =
        state[projectsURI].pagination;

      return getData(projectsURI, {
        ...params,
        page,
        perPage,
        search,
        sortDirection,
        sortColumn,
      });
    } catch (error) {
      // Dispatch setError action with error details
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error fetching projects',
          errorTitle: 'Fetch Projects Error',
        })
      );

      // Use rejectWithValue to handle errors for the thunk
      return rejectWithValue(
        error.response?.data?.error || 'Unknown error occurred'
      );
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const fetchProject = createAsyncThunk(
  `${projectsURI}/fetch-project`,
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      const project = await getData(`${projectsURI}/${id}`);

      return project;
    } catch (error) {
      // Dispatch setError action with error details
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error fetching project',
          errorTitle: 'Fetch Project Error',
        })
      );

      // Use rejectWithValue to handle errors for the thunk
      return rejectWithValue(
        error.response?.data?.error || 'Unknown error occurred'
      );
    } finally {
      dispatch(hideLoader());
    }
  }
);

// Async thunk to favorite a project
export const favoriteProject = createAsyncThunk(
  `${projectsURI}/favorite`,
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      // Attempt to favorite the project and store the response
      const response = await addData(`${projectsURI}/favorite/${id}`);

      dispatch(hideLoader()); // End loading process
      return response; // Return the successful response
    } catch (error) {
      // Dispatch setError action with error details
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error favoriting project',
          errorTitle: 'Favorite Project Error',
        })
      );

      // Reject the thunk with the error
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const addProject = createAsyncThunk(
  `${projectsURI}/addProject`,
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      const newProject = await addData(projectsURI, payload);

      // Optionally, fetch all projects if needed
      dispatch(fetchProjects());

      // Return the new project as the fulfilled value
      return newProject;
    } catch (error) {
      // Dispatch setError action with error details
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error adding project',
          errorTitle: 'Add Project Error',
        })
      );

      // Use rejectWithValue to handle errors for the thunk
      return rejectWithValue(
        error.response?.data?.error || 'Unknown error occurred'
      );
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const editProject = createAsyncThunk(
  `${projectsURI}/edit`,
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      const existingProject = await editData(projectsURI, payload);

      dispatch(fetchProjects()); // Fetch all projects (optional

      console.log(existingProject);

      return existingProject;
    } catch (error) {
      // Dispatch setError action with error details
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error editing project',
          errorTitle: 'Editing Project Error',
        })
      );

      // Use rejectWithValue to handle errors for the thunk
      return rejectWithValue(
        error.response?.data?.error || 'Unknown error occurred'
      );
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const deleteProject = createAsyncThunk(
  `${projectsURI}/delete`,
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      const response = await deleteData(`${projectsURI}/${id}`);

      dispatch(fetchProjects());

      return response;
    } catch (error) {
      // Dispatch setError action with error details
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error deleting project',
          errorTitle: 'Delete Project Error',
        })
      );

      return rejectWithValue(error); // Reject the thunk with the error
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const archiveProject = createAsyncThunk(
  `${projectsURI}/archive`,
  async ({ id, isArchived = false }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      const response = await patchData(`${projectsURI}/archive/${id}`);

      dispatch(fetchProjects({ isArchived }));

      return response;
    } catch (error) {
      // Dispatch setError action with error details
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error archive project',
          errorTitle: 'Archive Project Error',
        })
      );

      return rejectWithValue(error); // Reject the thunk with the error
    } finally {
      dispatch(hideLoader());
    }
  }
);

// Async thunk to edit hours bank of a project
export const editHoursBank = createAsyncThunk(
  `${projectsURI}/hoursBank`,
  async ({ data, params }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      const response = await editData(`${projectsURI}/hoursBank`, data, params);

      dispatch(hideLoader()); // End loading

      return response;
    } catch (error) {
      dispatch(
        setError({
          // Dispatch setError action with error details
          error: error.response?.data?.error || 'Error editing hours bank',
          errorTitle: 'Edit Hours Bank Error',
        })
      );
      return rejectWithValue(error); // Reject the thunk with the error
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const deleteTask = createAsyncThunk(
  `${tasksURI}/delete`,
  async (id, { getState, dispatch }) => {
    try {
      dispatch(setLoader()); // Start loading process

      const state = getState();

      const projectId = state[projectsURI].selectedProject.data._id;

      const response = await deleteData(
        `${tasksURI}/${id}/project/${projectId}`
      );

      // dispatch(fetchProject(projectId));

      return response;
    } catch (error) {
      // Dispatch setError action with error details
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error delete task',
          errorTitle: 'Archive Project Error',
        })
      );

      return rejectWithValue(error); // Reject the thunk with the error
    } finally {
      dispatch(hideLoader());
    }
  }
);

// Async thunk to fetch notifications
export const fetchNotifications = createAsyncThunk(
  `${notificationsURI}/all`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      return await getData(notificationsURI);
    } catch (error) {
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error fetching notifications',
          errorTitle: 'Fetch Notifications Error',
        })
      );
      return rejectWithValue(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

// Async thunk to mark notification as seen
export const markNotificationAsSeen = createAsyncThunk(
  `${notificationsURI}/seen`,
  async ({ notificationId }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      return await editData(`${notificationsURI}/`, { notificationId });
    } catch (error) {
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error marking notification',
          errorTitle: 'Mark Notification Error',
          timeout: false,
          warning: false,
        })
      );
      return rejectWithValue(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

// Async thunk to create a new tag
export const createNewTag = createAsyncThunk(
  `${tagsURI}/create`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      return await addData(tagsURI, { ...params });
    } catch (error) {
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error creating tag',
          errorTitle: 'Create Tag Error',
          timeout: false,
          warning: false,
        })
      );
      return rejectWithValue(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

// Async thunk to fetch tags
export const fetchTags = createAsyncThunk(
  `${tagsURI}/fetch`,
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      return await getData(tagsURI, { ...params });
    } catch (error) {
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error fetching tags',
          errorTitle: 'Fetch Tags Error',
          timeout: false,
          warning: false,
        })
      );
      return rejectWithValue(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

const initialState = {
  loading: 'idle',
  data: [],
  selectedProject: {
    loading: 'idle',
    data: {},
  },
  selectedTask: null,
  showTaskModal: false,
  pagination: {
    page: 1,
    perPage: 10,
    total: 0,
    search: '',
    sortDirection: 'asc',
    sortColumn: 'name',
  },
  notifications: [],
  tags: [],
};

const projectsSlice = createSlice({
  name: projectsURI,
  initialState,
  reducers: {
    addProjectsData: (state, { payload }) => {
      return [...payload];
    },
    setPaginate: (state, { payload }) => {
      return {
        ...state,
        pagination: { ...state.pagination, ...payload },
      };
    },
    setSelectedTask: (state, { payload }) => {
      state.selectedTask = payload;
    },
    showTaskModal: (state) => {
      state.showTaskModal = true;
    },
    setSelectedProject: (state, { payload }) => {
      state.selectedProject.data = payload ?? {
        name: '',
        company: '',
        tags: [],
      };
    },
    resetSelectedProject: (state) => {
      state.selectedProject.data = {};
    },
    resetSelectedTask: (state) => {
      state.selectedTask = null;
      state.showTaskModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchProjects.fulfilled, (state, { payload }) => {
        state.data = payload.list;
        state.pagination = { ...state.pagination, ...payload.pagination };
        state.loading = 'idle';
      })
      .addCase(fetchProjects.rejected, (state) => {
        console.error('fetch projects rejected!');
      })
      .addCase(fetchProject.pending, (state) => {
        state.selectedProject.loading = 'pending';
      })
      .addCase(fetchProject.fulfilled, (state, { payload }) => {
        state.selectedProject.loading = 'idle';
        state.selectedProject.data = payload;
      })
      .addCase(fetchProject.rejected, (state) => {
        console.error('fetch project rejected!');
        state.selectedProject.loading = 'idle';
      })
      .addCase(addProject.pending, (state) => {
        state.selectedProject.loading = 'pending';
      })
      .addCase(addProject.fulfilled, (state, { payload }) => {
        state.selectedProject.loading = 'idle';
        state.selectedProject.data = {};
      })
      .addCase(addProject.rejected, (state, { payload }) => {
        state.selectedProject.loading = 'pending';
        console.error(payload);
      })
      .addCase(editProject.fulfilled, (state, action) => {
        state.selectedProject.data = {};
      })

      .addCase(editProject.rejected, (state) => {
        console.error('edit project rejected!');
      })
      // .addCase(deleteProject.fulfilled, (state, { payload }) => {
      //   let newState = [...current(state.data)];
      //   const projectIndex = current(state.data).findIndex(
      //     (project) => project._id === payload.id
      //   );
      //   newState.splice(projectIndex, 1);
      //   state.data = newState;
      // })
      .addCase(deleteProject.rejected, (state) => {
        console.error('delete project rejected!');
      })
      .addCase(editHoursBank.fulfilled, (state, { payload }) => {
        if (state.selectedProject.data.hoursBank) {
          state.selectedProject.data.hoursBank = payload.hoursBank;
        }
      })

      .addCase(editHoursBank.rejected, (state) => {
        console.error('edit project rejected!');
      })
      .addCase(deleteTask.fulfilled, (state, { payload }) => {
        if (state.selectedProject.data.tasks) {
          state.selectedProject.data.tasks = [...payload.tasks];
        }
      })
      .addCase(deleteTask.rejected, (state) => {
        console.error('delete project rejected!');
      })
      .addCase(fetchNotifications.fulfilled, (state, { payload }) => {
        state.notifications = payload;
      })
      .addCase(markNotificationAsSeen.fulfilled, (state, { payload }) => {
        const notificationState = cloneDeep(state.notifications);

        const newNotifications = notificationState.map((notification) => {
          if (notification._id === payload.id) {
            notification.seen = true;
          }

          return notification;
        });

        state.notifications = newNotifications;
      })
      .addCase(createNewTag.fulfilled, (state, { payload }) => {
        const tagsState = cloneDeep(state.tags);

        const newTags = [...tagsState, payload];

        state.tags = newTags;
      })
      .addCase(fetchTags.fulfilled, (state, { payload }) => {
        state.tags = payload;
      });
  },
});

const { reducer, actions } = projectsSlice;

export const projectsEntities = ({ projects }) => {
  const isLoading = projects.loading === 'pending';
  return { projects: projects.data, isLoading };
};

export const projectsTags = ({ projects }) => {
  return { tags: projects.tags };
};

export const selectedProjectEntity = ({ projects }) => {
  const isLoading = projects.selectedProject.loading === 'pending';
  const project = projects.selectedProject.data;

  return { project, isLoading };
};

export const selectedTaskEntity = ({ projects }) => {
  return {
    selectedTask: projects.selectedTask,
    showTaskModal: projects.showTaskModal,
    selectedProject: projects.selectedProject.data,
  };
};

export const {
  addProjectsData,
  setPaginate,
  setSelectedProject,
  resetSelectedProject,
  resetSelectedTask,
  setSelectedTask,
  showTaskModal,
} = actions;

export default reducer;
