// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  error: {
    isOpen: false,
    error: "You can contact technical support",
    errorTitle: "An error occurred on the server",
    timeout: false,
    warning: false,
  },
  loader: {
    isOpen: false,
    message: "loading...",
  },
};

const quantomStateSlice = createSlice({
  name: "quantomState",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = { ...payload, isOpen: true };
    },
    hideError: (state) => {
      state.error = {
        isOpen: false,
        error: "You can contact technical support",
        errorTitle: "An error occurred on the server",
        timeout: false,
        warning: false,
      };
    },
    setLoader: (state) => {
      state.loader.isOpen = true;
    },
    hideLoader: (state) => {
      state.loader = {
        isOpen: false,
        message: "loading...",
      };
    },
  },
  extraReducers: (builder) => {
    builder;
  },
});

export const errorEntity = ({ quantomState }) => quantomState.error;
export const loaderEntity = ({ quantomState }) => quantomState.loader;

const { reducer, actions } = quantomStateSlice;

export const { setError, hideError, setLoader, hideLoader } = actions;

export default reducer;
