import axios, { AxiosInstance } from 'axios';

// Define your baseURL using the environment variables
const baseURL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_URI}`;

// Create an Axios instance with the baseURL and default headers set
const api: AxiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default api;
