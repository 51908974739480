import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { hideError, errorEntity } from "../../../redux/quantomState";
import { useDispatch, useSelector } from "react-redux";
import { FaTimesCircle } from "react-icons/fa"; // Import the error icon

// ErrorModal Component as an Arrow Function
const ErrorModal = (args) => {
  // Extracting state from Redux Store
  const { isOpen, error, errorTitle, timeout, warning } =
    useSelector(errorEntity);
    
  const dispatch = useDispatch();

  // Effect for Auto-hiding the Modal
  useEffect(() => {
    if (isOpen && timeout) {
      // Set a timer to automatically dispatch hideError action after 3 seconds
      const timer = setTimeout(() => {
        dispatch(hideError());
      }, 3000);

      // Cleanup function to clear the timer when the component unmounts or the dependencies change
      return () => clearTimeout(timer);
    }
  }, [isOpen, timeout, dispatch]);

  // Hide function to manually close the modal
  const hide = () => dispatch(hideError());

  return (
    <Modal isOpen={isOpen} toggle={hide} {...args} centered>
      <ModalHeader toggle={hide}>{errorTitle}</ModalHeader>
      <ModalBody>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "200px", // Example height, adjust as needed
            justifyContent: "center", // This will vertically center the content
          }}
        >
          <FaTimesCircle
            color="red"
            size={100}
            style={{ verticalAlign: "middle", marginBottom: "30px" }}
          />
          <div>{error}</div>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={hide}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ErrorModal;
