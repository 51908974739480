// ** React Imports
import ReactDOM from "react-dom";
import { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";
import setUpInterceptor from "./api/interceptor";

// ** Theme Context Provider
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Global Styles and Configuration
import themeConfig from "./configs/themeConfig";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Component Imports
import Spinner from "./@core/components/spinner/Fallback-spinner";
import { Toaster } from "react-hot-toast";

// ** Utilities and Services
import * as serviceWorker from "./serviceWorker";

// ** Core third-party styles and scripts
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/components/ripple-button";
import ErrorModal from "./components/modals/states/ErrorModal";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

// Set up axios interceptors for API calls
setUpInterceptor();

ReactDOM.render(
  <BrowserRouter>
  
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <LazyApp />
          <Toaster
            position={themeConfig.layout.toastPosition}
            toastOptions={{ className: "react-hot-toast" }}
          />
          
        </ThemeContext>
      </Suspense>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// Service worker setup for PWA capabilities and offline support
// Change `unregister()` to `register()` to enable the service worker
// Note: Registering a service worker comes with some pitfalls and considerations.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
