import Cookies from 'js-cookie';

const USER_DATA = 'user-data';
const USER_TOKEN = 'user-token';

export const saveUserOnCookie = (userData, changeToken = true) => {
  localStorage.setItem(USER_DATA, JSON.stringify({ user: userData.user }));

  changeToken && Cookies.set(USER_TOKEN, userData.token, {
    expires: 1,
    sameSite: 'strict',
    // secure: true,
  });
};

export const deleteUserOnCookie = () => {
  Cookies.remove(USER_TOKEN, { sameSite: 'strict' });
  localStorage.removeItem(USER_DATA);
};

export const getUserFromCookie = () => {
  const jsonUserToken = Cookies.get(USER_TOKEN);

  if (jsonUserToken === undefined) return null;

  return {
    ...JSON.parse(localStorage.getItem(USER_DATA)),
    token: jsonUserToken,
  };
};
