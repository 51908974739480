import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addData, getData } from '../api/data';
import { deleteUserOnCookie, saveUserOnCookie } from '../cookies/cookies';
import { hideLoader, setError, setLoader } from './quantomState';

const workersURI = 'workers';

export const login = createAsyncThunk(
  `${workersURI}/admin-login`,
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader());
      const userData = await addData(`${workersURI}/admin-login`, { email, password });
      return userData;
    } catch (error) {
      dispatch(setError({
        error: error.response?.data?.error || "Error during login",
        errorTitle: "Login Error",
        timeout: false,
        warning: false,
      }));
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const fetchUserInfo = createAsyncThunk(
  `${workersURI}/me`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader());
      const userInfo = await getData(`${workersURI}/me`);
      return userInfo;
    } catch (error) {
      dispatch(setError({
        error: error.response?.data?.error || "Error fetching user info",
        errorTitle: "Fetch User Info Error",
        timeout: false,
        warning: false,
      }));
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const logout = createAsyncThunk(
  `${workersURI}/logout`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader());
      await addData(`${workersURI}/logout`);
    } catch (error) {
      dispatch(setError({
        error: error.response?.data?.error || "Error during logout",
        errorTitle: "Logout Error",
        timeout: false,
        warning: false,
      }));
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader());
    }
  }
);


const initialState = { user: null, token: '' };

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginFromCookie: (state, { payload }) => {
      return payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        saveUserOnCookie(payload);
        return payload;
      })
      .addCase(logout.fulfilled, () => {
        deleteUserOnCookie();
        return initialState;
      })
      .addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
        saveUserOnCookie(payload, false);
        return { ...payload, token: state.token };
      });
    // Add other cases as needed
  },
});

const { reducer, actions } = loginSlice;

export const { loginFromCookie } = actions;

export default reducer;
