import { AxiosError, AxiosRequestConfig } from "axios";
import instance from "./index";
import { store } from "../redux/store";

const setUpInterceptor = (): void => {
  const handleError = (error: AxiosError): Promise<AxiosError> => {
    // Handle the error as needed, maybe refresh token or log out user
    return Promise.reject(error);
  };

  // Set up request interceptor
  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = store.getState().login.token; // Assuming the token is directly in login

    // If token exists, add it to the request's Authorization header
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  }, handleError); // Directly using handleError for request error handling

  // Set up response interceptor
  instance.interceptors.response.use((response) => response, handleError);
};

export default setUpInterceptor;
