import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData, addData, deleteData, editData } from '../api/data';
import { hideLoader, setError, setLoader } from './quantomState';

// Defining the base URI for the API endpoint
export const companiesURI = 'companies';

// Async thunk for fetching companies
export const fetchCompanies = createAsyncThunk(
  `${companiesURI}/fetch`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState();

      // let sortColumn = '';

      // let sortDirection = '';

      // if (params && 'sortColumn' in params) {
      //   sortColumn = params.sortColumn.toLowerCase();
      // }

      // if (params && 'sortDirection' in params) {
      //   sortDirection = params.sortDirection;
      // }

      const { page, perPage, search, sortDirection, sortColumn } =
        state[companiesURI].pagination;

      dispatch(setLoader());

      return await getData(companiesURI, {
        sortColumn,
        sortDirection,
        page,
        perPage,
        search,
        users: 0,
        ...params,
      });
    } catch (error) {
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error fetching companies',
          errorTitle: 'Fetch Companies Error',
        })
      );
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader());
    }
  }
);

// Async thunk for adding a new company
export const newCompany = createAsyncThunk(
  `${companiesURI}/add`,
  async ({ company }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader());
      const data = await addData(companiesURI, company);
      return { data, projects: company.projects };
    } catch (error) {
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error adding company',
          errorTitle: 'New Company Error',
        })
      );
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader());
    }
  }
);

// Async thunk for editing a company
export const editCompany = createAsyncThunk(
  `${companiesURI}/edit`,
  async ({ company }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader());
      const payload = {
        ...company,
        name: company.companyName,
      };

      return await editData(companiesURI, payload);
    } catch (error) {
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error editing company',
          errorTitle: 'Edit Company Error',
        })
      );
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader());
    }
  }
);

// Async thunk for deleting a company
export const deleteCompany = createAsyncThunk(
  `${companiesURI}/delete`,
  async ({ companyId }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader());
      await deleteData(companiesURI, { _id: companyId });
    } catch (error) {
      dispatch(
        setError({
          error: error.response?.data?.error || 'Error deleting company',
          errorTitle: 'Delete Company Error',
        })
      );
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader());
    }
  }
);

// Initial state for the companies slice
const initialState = {
  loading: 'idle',
  data: [],
  pagination: {
    page: 1,
    perPage: 10,
    total: 0,
    search: '',
    sortDirection: 'asc',
    sortColumn: 'name',
  },
};

const companiesSlice = createSlice({
  name: companiesURI,
  initialState,
  reducers: {
    // Reducer for adding a company directly without async operation
    addCompany: (state, { payload }) => state.data.push(payload),
    setPaginate: (state, { payload }) => {
      return {
        ...state,
        pagination: { ...state.pagination, ...payload },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling pending state for fetching companies
      .addCase(fetchCompanies.pending, (state) => {
        state.loading = 'pending';
      })
      // Handling fulfilled state for fetching companies
      .addCase(fetchCompanies.fulfilled, (state, { payload }) => {
        state.data = payload.list;
        state.pagination = { ...state.pagination, ...payload.pagination };
        state.loading = 'idle';
      })
      // Handling rejected state for fetching companies
      .addCase(fetchCompanies.rejected, () => {
        console.error('fetch companies rejected!');
      })
      // Handling fulfilled state for adding a new company
      .addCase(
        newCompany.fulfilled,
        (state, { payload: { data, projects } }) => {
          state.data.push({ ...data, projects });
        }
      )
      // Handling rejected state for adding a new company
      .addCase(newCompany.rejected, () => {
        console.error('failed to add company!');
      })
      // Handling fulfilled state for editing a company
      .addCase(editCompany.fulfilled, (state, { payload }) => {
        const index = state.data.findIndex(
          (company) => company._id === payload._id
        );
        if (index !== -1) state.data[index] = payload;
      })
      // Handling rejected state for editing a company
      .addCase(editCompany.rejected, () => {
        console.error('error in editing a company');
      })
      // Handling fulfilled state for deleting a company
      .addCase(deleteCompany.fulfilled, (state, { payload }) => {
        state.data = state.data.filter(
          (company) => company._id !== payload._id
        );
      })
      // Handling rejected state for deleting a company
      .addCase(deleteCompany.rejected, () => {
        console.error('delete project rejected!');
      });
  },
});

// Exporting the reducer and actions from the slice
export const { reducer, actions } = companiesSlice;

// Selector function to get companies entities
export const companiesEntities = ({ companies }) => {
  const isLoading = companies.loading === 'pending';
  return { companies: companies.data, isLoading };
};

// Exporting specific actions
export const { addCompany, setPaginate } = actions;

// Default export of the reducer
export default reducer;
