import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { addData, getData, editData, deleteData, patchData } from '../api/data';
import { hideLoader, setError, setLoader } from './quantomState';

const URI = 'settings';

export const fetchSettings = createAsyncThunk(
  `${URI}/fetch`,
  async (token, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading
      // Fetch settings data
      const data = await getData(URI, null, token);
      return data; // Return fetched data
    } catch (error) {
      // Handle errors
      dispatch(
        setError({
          error: error.message || 'Error fetching settings',
          errorTitle: 'Fetch Settings Error',
          timeout: false,
          warning: false,
        })
      );
      return rejectWithValue(error.message || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader()); // End loading
    }
  }
);

export const newBrand = createAsyncThunk(
  `${URI}/add`,
  async (
    {
      brand,
      selectedLogo = null,
      selectedSignature = null,
      filePriceOne = null,
      filePriceTwo = null,
      token,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoader()); // Start loading
      let formData = new FormData();

      // Populate formData with brand information
      for (const key in brand) {
        formData.append(key, brand[key]);
      }

      // Append additional files to formData
      formData.append('brandLogo', selectedLogo);
      formData.append('brandSignature', selectedSignature);
      formData.append('filePriceOne', filePriceOne);
      formData.append('filePriceTwo', filePriceTwo);

      // Add new brand settings
      const data = await addData(URI, formData, token, {
        'Content-Type': 'multipart/form-data',
      });
      return { data }; // Return the response data
    } catch (error) {
      // Handle errors
      dispatch(
        setError({
          error: error.message || 'Error adding new brand',
          errorTitle: 'New Brand Error',
          timeout: false,
          warning: false,
        })
      );
      return rejectWithValue(error.message || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader()); // End loading
    }
  }
);

// export const updateBrand = createAsyncThunk(
//   'settings/edit',
//   async ({
//     brandData: {
//       brand,
//       selectedLogo,
//       selectedSignature,
//       filePriceOne,
//       filePriceTwo,
//     },
//     token,
//   }) => {
//     try {
//       let formData = new FormData();

//       for (const key in brand) {
//         formData.append(key, brand[key]);
//       }

//       formData.append('brandLogo', selectedLogo);
//       formData.append('brandSignature', selectedSignature);
//       formData.append('filePriceOne', filePriceOne);
//       formData.append('filePriceTwo', filePriceTwo);

//       const data = await editData('settings/edit', formData, null, token, {
//         'Content-Type': 'multipart/form-data',
//       });

//       return data;
//     } catch (err) {
//       throw new Error('edit brand error', err.message);
//     }
//   }
// );

export const updateBrand = createAsyncThunk(
  `${URI}/edit`,
  async (
    { brand, selectedLogo, selectedSignature, filePriceOne, filePriceTwo },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoader()); // Start loading
      let formData = new FormData();

      // Populate formData with brand information
      for (const key in brand) {
        formData.append(key, brand[key]);
      }

      // Append additional files to formData
      formData.append('brandLogo', selectedLogo);
      formData.append('brandSignature', selectedSignature);
      formData.append('filePriceOne', filePriceOne);
      formData.append('filePriceTwo', filePriceTwo);

      const data = await editData(URI, formData, null, {
        'Content-Type': 'multipart/form-data',
      });
      return data; // Return the response data
    } catch (error) {
      // Handle errors
      dispatch(
        setError({
          error: error.message || 'Error updating brand',
          errorTitle: 'Update Brand Error',
          timeout: false,
          warning: false,
        })
      );
      return rejectWithValue(error.message || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader()); // End loading
    }
  }
);

export const deleteBrand = createAsyncThunk(
  `${URI}/delete`,
  async ({ settingsId }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading
      const data = await deleteData(URI, { id: settingsId });
      return data; // Return the response data
    } catch (error) {
      // Handle errors
      dispatch(
        setError({
          error: error.message || 'Error deleting brand',
          errorTitle: 'Delete Brand Error',
          timeout: false,
          warning: false,
        })
      );
      return rejectWithValue(error.message || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader()); // End loading
    }
  }
);

export const changeNotificationPercentages = createAsyncThunk(
  `${URI}/notifications`,
  async (
    { firstNotificationPercentageOption, secondNotificationPercentageOption },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setLoader()); // Start loading
      const data = await patchData(`${URI}/notifications`, {
        firstNotificationPercentageOption,
        secondNotificationPercentageOption,
      });
      return data; // Return the response data
    } catch (error) {
      // Handle errors
      dispatch(
        setError({
          error: error.message || 'Error changing notification percentages',
          errorTitle: 'Change Notifications Error',
          timeout: false,
          warning: false,
        })
      );
      return rejectWithValue(error.message || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader()); // End loading
    }
  }
);

export const fetchNotificationPercentages = createAsyncThunk(
  `${URI}/get-notifications`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading
      const data = await getData(`${URI}/notifications`);
      return data; // Return the response data
    } catch (error) {
      // Handle errors
      dispatch(
        setError({
          error: error.message || 'Error fetching notification percentages',
          errorTitle: 'Fetch Notification Percentages Error',
          timeout: false,
          warning: false,
        })
      );
      return rejectWithValue(error.message || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader()); // End loading
    }
  }
);

const initialState = [];

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    addBrand: (state, { payload }) => {
      state.push(payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(fetchSettings.rejected, () => {
        console.error('fetch settings rejected!');
      })
      .addCase(newBrand.fulfilled, (state, { payload: { data } }) => {
        state.push(data);
      })
      .addCase(newBrand.rejected, () => {
        console.error('failed to add a new brand!');
      })
      .addCase(updateBrand.fulfilled, (state, { payload }) => {
        const brandIndex = current(state).findIndex(
          (brand) => brand._id === payload._id
        );
        if (brandIndex !== -1) {
          state[brandIndex] = payload;
        }
      })
      .addCase(updateBrand.rejected, () => {
        console.error('edit brand settings rejected!');
      })
      .addCase(deleteBrand.fulfilled, (state, { payload }) => {
        const settingsIndex = current(state).findIndex(
          (settings) => settings._id === payload._id
        );
        if (settingsIndex !== -1) {
          state.splice(settingsIndex, 1);
        }
      })
      .addCase(deleteBrand.rejected, () => {
        console.error('delete brand rejected!');
      });
  },
});

const { reducer, actions } = settingsSlice;
export const { addBrand } = actions;
export default reducer;
