import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { addData, deleteData, editData, getData, patchData } from '../api/data';
import { hideLoader, setError, setLoader } from './quantomState';

export const workersURI = 'workers';

export const fetchWorkers = createAsyncThunk(
  `${workersURI}/fetch`,
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoader()); // Start loading process

      const state = getState();

      return getData(workersURI, {
        ...params,
        ...state[workersURI].pagination,
      });
    } catch (error) {
      // Dispatch setError action with error details
      dispatch(
        setError({
          error: error.response?.data?.error || `Error fetching ${workersURI}`,
          errorTitle: 'Fetch Projects Error',
        })
      );

      // Use rejectWithValue to handle errors for the thunk
      return rejectWithValue(error.response?.data?.error || 'Unknown error occurred');
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const fetchWorkersOptions = createAsyncThunk(
  'workers/fetch-options',
  async (params) => await getData(workersURI, { byRole: 'Designer', perPage: 50000 })
);

export const fetchTimeSheet = createAsyncThunk('users/fetch-timesheet', async (date) =>
  getData(`${workersURI}/timeSheet`, { start: date[0], end: date[1] })
);

export const addWorker = createAsyncThunk(
  `${workersURI}/add`,
  async ({ user }) => await addData(workersURI, user)
);

export const editWorker = createAsyncThunk(
  `${workersURI}/edit`,
  async ({ user }) => await editData(workersURI, user)
);

export const deleteUser = createAsyncThunk(
  `${workersURI}/delete`,
  async ({ userId }) => await deleteData(`${workersURI}/${userId}`)
);

export const disableUser = createAsyncThunk(
  `${workersURI}/disable`,
  async ({ userId }) => await patchData(`${workersURI}/disable`, { id: userId })
);

export const deleteDayOff = createAsyncThunk('dayoff/delete', async ({ dayOffId, userId }) => {
  await deleteData('dayoff/delete', { id: dayOffId });

  return {
    id: dayOffId,
    userId: userId,
  };
});

export const editDayOff = createAsyncThunk(
  'dayoff/edit',
  async ({ dayOffId, dayOffHalfDay, dayOffDate, dayOffType, userId }) => {
    await editData('dayoff/edit', {
      id: dayOffId,
      halfDay: dayOffHalfDay,
      date: dayOffDate,
      type: dayOffType,
    });

    return {
      id: dayOffId,
      halfDay: dayOffHalfDay,
      date: dayOffDate,
      type: dayOffType,
      userId: userId,
    };
  }
);

const initialState = {
  loading: 'idle',
  data: [],
  pagination: {
    page: 1,
    perPage: 10,
    total: 0,
    search: '',
    sortDirection: 'asc',
    sortColumn: 'usernmame',
  },
  workerOptions: {
    loading: 'idle',
    data: {},
  },
  timesheet: {
    loading: 'idle',
    data: [],
  },
};

const workersSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    addUser: (state, { payload }) => {
      return [...state, payload];
    },
    setPaginate: (state, { payload }) => {
      return {
        ...state,
        pagination: { ...state.pagination, ...payload },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkers.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchWorkers.fulfilled, (state, { payload }) => {
        state.data = payload.list;
        state.pagination = { ...state.pagination, ...payload.pagination };
        state.loading = 'idle';
      })
      .addCase(fetchWorkers.rejected, (state, action) => {
        // Log the error message
        console.error('Fetch workers rejected:', action.error.message);

        // If you need the entire error object, you can log it as well
        console.error(action.error);

        // You can also store the error in your Redux state if needed
        state.error = action.error.message;
      })
      .addCase(fetchWorkersOptions.pending, (state) => {
        state.workerOptions.loading = 'pending';
      })
      .addCase(fetchWorkersOptions.fulfilled, (state, { payload }) => {
        state.workerOptions.data = payload.list;
        state.workerOptions.loading = 'idle';
      })
      .addCase(fetchWorkersOptions.rejected, () => {
        console.error('fetch workers rejected!');
      })
      .addCase(addWorker.fulfilled, (state, { payload }) => {
        state.data = [...state.data, payload].sort((a, b) =>
          a.username.toLowerCase() < b.username.toLowerCase() ? -1 : 1
        );
      })
      .addCase(addWorker.rejected, (state) => {
        console.log('error in adding a worker');
      })
      .addCase(editWorker.fulfilled, (state, { payload }) => {
        state.data = payload;
      })
      .addCase(editWorker.rejected, (state) => {
        console.log('error in editing a worker');
        state.data = [];
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        let newState = [...current(state.data)];
        const userIndex = current(state.data).findIndex((user) => user._id === payload.id);
        newState.splice(userIndex, 1);
        state.data = newState;
      })
      .addCase(deleteUser.rejected, () => {
        console.error('delete project rejected!');
      })
      .addCase(deleteDayOff.fulfilled, (state, { payload }) => {
        let newState = cloneDeep(state.data);

        const userIndex = newState.findIndex((user) => user.id === payload.userId);

        const newDaysOff = newState[userIndex].daysOff.filter(
          (dayOff) => dayOff._id !== payload.id
        );
        newState[userIndex].daysOff = newDaysOff;
        state.data = newState;
      })
      .addCase(editDayOff.fulfilled, (state, { payload }) => {
        let newState = cloneDeep(state.data);

        const userIndex = newState.findIndex((user) => user.id === payload.userId);
        const dayOffIndex = newState[userIndex].daysOff.findIndex(
          (dayOff) => dayOff._id === payload.id
        );

        newState[userIndex].daysOff[dayOffIndex] = {
          ...newState[userIndex].daysOff[dayOffIndex],
          type: payload.type,
          halfDay: payload.halfDay,
          date: payload.date,
        };

        state.data = newState;
      })
      .addCase(disableUser.fulfilled, (state, { payload }) => {
        let newState = cloneDeep(state.data);
        const userIndex = current(state.data).findIndex((user) => user._id === payload.id);
        newState[userIndex].disabled = newState[userIndex].disabled === true ? false : true;
        state.data = newState;
      })
      .addCase(fetchTimeSheet.pending, (state) => {
        state.timesheet.loading = 'pending';
      })
      .addCase(fetchTimeSheet.fulfilled, (state, { payload }) => {
        state.timesheet.data = payload; // Handle timesheet data
        state.timesheet.loading = 'idle';
      })
      .addCase(fetchTimeSheet.rejected, () => {
        console.error('fetch timesheet rejected!');
      });
  },
});

const { reducer, actions } = workersSlice;

export const workersEntities = ({ workers }) => {
  const isLoading = workers.loading === 'pending';
  return { users: workers.data, isLoading };
};

export const workersOptionsEntities = ({ workers }) => {
  const isLoading = workers.workerOptions.loading === 'pending';
  return { users: workers.workerOptions.data, isLoading };
};

export const timesheetEntities = ({ workers }) => {
  const isLoading = workers.timesheet.loading === 'pending';
  return { users: workers.timesheet.data, isLoading };
};

export const { addUser, setPaginate } = actions;

export default reducer;
