import api from ".";

const handleRequest = async (request, ...args) => {
  try {
    const { data } = await request(...args);
    return data;
  } catch (err) {
    // Depending on your error handling strategy, you may want to log this error or handle it differently
    console.error(err);
    throw err;
  }
};

export const getData = (query, params) =>
  handleRequest(api.get, query, { params });

export const addData = (query, data, headersData = {}) =>
  handleRequest(api.post, query, data, { headers: headersData });

export const editData = (query, data, params = {}, headersData = {}) =>
  handleRequest(api.put, query, data, { params, headers: headersData });

export const patchData = (query, data, params = {}, headersData = {}) =>
  handleRequest(api.patch, query, data, { params, headers: headersData });

export const deleteData = (query, data) =>
  handleRequest(api.delete, query, { data });
